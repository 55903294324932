import React, { Component } from 'react';
 
import companyLogo from '../images/logo.svg';

class App extends Component {
  render() {
    return (
      <div id='main' className='loginform'>
        <div className='d-flex align-items-center justify-content-center h-100'>
          <div className='card'>
            <div className='card-body'>
              <form action='/statistics'>
                <div className='text-center'>
                  <img src={companyLogo} className='logo' alt="BigCo Inc. logo"/>
                </div>
                <div className="form-group mt-4">
                  <label className='form-label'>Username </label>
                  <input type="text" name="uname"  className="form-control" required />

                </div>
                <div className="input-container">
                  <label className='form-label'>Password </label>
                  <input type="password" name="pass"  className="form-control" required />
                </div>
                <div className="mt-4">
                  <input type="submit" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;