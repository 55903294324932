import React, { Component } from 'react';
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import Login from './component/login'; 
import Statistics from './component/statistics';

import './style.css';
  
class App extends Component {
  render() {
    return (
       <Router> 
           <Routes>
                 <Route exact path='/' element={< Login />}></Route> 
                 <Route exact path='/statistics' element={< Statistics />}></Route>
                 
          </Routes> 
       </Router>
   );
  }
}
  
export default App;